import styled, { css } from 'styled-components';

export const PrescribedProductCardWrapper = styled.div<{
  isHeaderPresent: boolean;
  hideRightArrow: boolean;
}>`
  border-radius: var(--dls-size-4);
  overflow: hidden;
  ${({ isHeaderPresent }) =>
    isHeaderPresent &&
    css`
      border: var(--dls-size-1) solid #e0efec;
    `}
  .header {
    padding: var(--dls-size-4) 10px;
    background: #e0efec;
  }
  .body {
    padding: 10px 0;
    background: var(--brand-light-bg-color);
  }
  .detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--dls-size-8);
    padding: 0 10px;
    cursor: pointer;
    ${({ hideRightArrow }) =>
      hideRightArrow &&
      css`
        pointer-events: none;
        cursor: default;
      `}
    .info {
      width: 100%;
    }
  }
  .img-container {
    border-radius: var(--dls-size-4);
    border: 0.5px solid var(--brand-primary-white-color);
    width: 36px;
    height: 36px;
  }
  .product-image {
    width: 36px;
    height: 36px;
  }
  .divider {
    background: var(--brand-primary-white-color);
    height: var(--dls-size-1);
    margin: 10px 0;
  }
  .frequency {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }
  .instruction {
    padding: 0 10px;
  }
  .frequency-time {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-4);
  }
  .info {
    color: var(--brand-light-secondary-text-color);
  }
  .prescribed-for {
    padding: var(--dls-size-4) 10px;
    text-align: center;
    background: var(--dls-brand-primary-color-100);
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .title {
    width: 100%;
  }
`;

import { Controls, Player } from '@lottiefiles/react-lottie-player';

/**
 * LottiePlayer is a wrapper component and is used to display the lottie animation.
 * @param {Object} props
 * @param {IPlayerProps["src"]} props.src - The path to the lottie animation file or the JSON object itself.
 * @param {boolean} [props.controlsVisible] - Whether the controls are visible or not.
 * @param {string[]?} [props.controlsButtons] - The buttons to be displayed in the controls.
 * @param {boolean?} [props.autoplay] - Whether the animation should start playing automatically. Defaults to true
 * @param {boolean?} [props.loop] - Whether the animation should loop or not. Defaults to true
 * @param {React.CSSProperties?} [props.style] - The style of the player.
 * @param {IPlayerProps["renderer"]?} [props.renderer] - The renderer to use
 * @param {IPlayerProps["onEvent"]?} [props.onEvent] - The callback to be called when an event is triggered.
 * @param {IPlayerProps["onStateChange"]?} [props.onStateChange] - The callback to be called when the state of the player changes.
 * @param {IPlayerProps["speed"]?} [props.speed] - The speed of the animation.
 * @returns {JSX.Element}
 */
export default function LottiePlayer(props: any) {
  const { autoplay, loop, ...rest } = props;
  return (
    <Player
      {...rest}
      autoplay={autoplay ?? true}
      loop={loop ?? true}
      style={props.style || { height: '200px', width: '200px' }}
    >
      <Controls
        visible={props.controlsVisible || false}
        buttons={props.controlsButtons || ['pause', 'play', 'stop']}
      />
    </Player>
  );
}

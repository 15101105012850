import { StyledExpandableSection } from './styles';
import useExpandableSection from './hook';
import { DropdownArrow, Typography, TypographyVariants } from '../../atomic';
import { ExpandableSectionProps } from '@mosaic-wellness/fe-types';

function ExpandableSection(props: ExpandableSectionProps) {
  const { title, children, isCollapsible = true } = props || {};

  const { isListOpen, handleToggleList } = useExpandableSection(props);

  return (
    <StyledExpandableSection isCollapsible={isCollapsible}>
      <div className="header" onClick={handleToggleList}>
        <Typography
          variant={TypographyVariants.BODY_BASE_REGULAR}
          customClassName="title"
        >
          {title}
        </Typography>
        {isCollapsible && (
          <DropdownArrow
            stroke="var(--brand-light-secondary-text-color)"
            isClosed={!isListOpen}
          />
        )}
      </div>
      {isListOpen ? children : null}
    </StyledExpandableSection>
  );
}

export default ExpandableSection;

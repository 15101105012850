import styled from 'styled-components';

export const RxInfoListWrapper = styled.ul`
  margin: 0;
  padding-top: var(--dls-size-8);
  padding-left: var(--dls-size-24);
  li {
    color: var(--brand-light-secondary-text-color);
  }
`;

import styled from 'styled-components';

export const StyledInfoBannerWrapper = styled.div<{ variant: string }>`
  padding: var(--dls-size-4);
  border-radius: var(--dls-size-8);
  text-align: center;
  background-color: ${({ variant }) =>
    variant === 'error'
      ? 'var(--dls-error-color-100)'
      : 'var(--dls-warning-color-100)'};
  .text {
    color: ${({ variant }) =>
      variant === 'error'
        ? 'var(--dls-error-color-500)'
        : 'var(--dls-warning-color-500)'};
  }
`;

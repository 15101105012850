import styled from 'styled-components';
const DropdownContainer = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  min-width: fit-content;
  width: 100%;
  background: white;
  border: 1px solid var(--dls-divider-light-color);
  box-shadow: 0px 119px 33px 0px rgba(0, 0, 0, 0.00), 0px 76px 30px 0px rgba(0, 0, 0, 0.01), 0px 43px 26px 0px rgba(0, 0, 0, 0.05), 0px 19px 19px 0px rgba(0, 0, 0, 0.09), 0px 5px 10px 0px rgba(0, 0, 0, 0.10);
  max-height: 200px;
  overflow-y: auto;
  z-index: 1;
  border-radius: var(--dls-size-8);
`;

const DropdownItem = styled.div`
  padding: var(--dls-size-16);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: var(--dls-size-4);
  .label {
    white-space: nowrap;
  }
`;

export { DropdownContainer, DropdownItem };

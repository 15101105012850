import {
  RxHabitInstructionsListItem,
  RxHabitInstructionsProps,
} from '@mosaic-wellness/fe-types';
import { OptimizedImage, Typography, TypographyVariants } from '../../atomic';
import { RxHabitInstructionsWrapper } from './styles';

const RxHabitInstructions = (props: RxHabitInstructionsProps) => {
  const { instructions = [], styles } = props;

  return (
    <RxHabitInstructionsWrapper
      style={{
        background: styles?.background || 'var(--dls-brand-primary-color-100)',
      }}
      data-testid="rx-habit-instructions-wrapper"
    >
      {instructions.map(
        (instruction: RxHabitInstructionsListItem, index: number) => (
          <div
            key={'instruction' + index}
            className="instruction-wrapper"
            data-testid="instruction-wrapper"
          >
            <OptimizedImage
              source={instruction.icon}
              customClassName="instruction-icon"
              data-testId={'instruction-icon-' + index}
            />
            <div>
              <Typography variant={TypographyVariants.TAG_BOLD}>
                {instruction.title}
              </Typography>
              <Typography
                variant={TypographyVariants.X_SMALL}
                customClassName="secondary-text"
              >
                {instruction.subTitle}
              </Typography>
            </div>
          </div>
        )
      )}
    </RxHabitInstructionsWrapper>
  );
};

export default RxHabitInstructions;

import React from 'react';
import { HEADING_TAGS_MAPPING } from '../../../constants';
import { TypographyWrapper } from './styles';
import { ITypography, TypographyVariants } from './types';

const Typography: React.FC<ITypography> = (props) => {
  const {
    variant = TypographyVariants.BODY_BASE_REGULAR,
    customClassName = '',
    headingLevel,
    children = '',
    testId = '',
    style = {},
    ...rest
  } = props;

  return (
    <TypographyWrapper
      className={`${variant} ${customClassName}`}
      as={HEADING_TAGS_MAPPING[headingLevel ?? 1] as any}
      data-testid={testId}
      style={style}
      {...rest}
    >
      {children}
    </TypographyWrapper>
  );
};

export default Typography;

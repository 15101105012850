import styled from 'styled-components';

const StyledContainer = styled.div<{ isOpen: boolean }>`
  width: 100%;
  position: relative;

  .label {
    color: var(--brand-light-secondary-text-color);
  }

  .inputContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    border-bottom: 1px solid var(--dls-divider-light-color);
    padding: var(--dls-size-8);
    cursor: pointer;

    input {
      border: none;
      cursor: pointer;
      outline: none;
      width: 100%;
      font-style: italic;
      font-size: 16px;
      color: var(--brand-light-secondary-text-color);

      &::placeholder {
        color: var(--dls-disabled-text-colors);
      }
    }

    .dropdownArrow {
      margin-left: var(--dls-size-8);
    }
    .dropdownArrow {
      transition: transform 0.3s ease;
      transform: rotate(180deg);
    }

    ${(props) =>
      props.isOpen &&
      `.dropdownArrow {
        transform: rotate(0deg);
      }`
    }
  }
`;
export { StyledContainer };

import styled from 'styled-components';

const StyledInputMain = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .secondary {
    color: var(--brand-light-secondary-text-color);
  }
`;

const StyledInput = styled.input`
  height: 48px;
  border: 1px solid var(--dls-divider-light-color);
  border-radius: 8px;
  width: 100%;
  font-size: 16px;
  padding: 0 16px;

  :focus {
    border: 1px solid var(--dls-divider-light-color);
  }
`;

export { StyledInput, StyledInputMain };

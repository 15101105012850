import { createGlobalStyle } from 'styled-components';

const StyledLJAE = createGlobalStyle<{
  theme: { fontFamily: string; color: any; sizes: any; lineHeight: any };
}>`
    * {
    box-sizing: border-box;
    }
    .viewport-container {
      width: 100%;
      
      @media (min-width:768px) {
        max-width: 1120px;
      }
    }

    div, span {
    -webkit-tap-highlight-color: transparent;
    }

    :root {
    --brand-font-family-title: ${(props) => props.theme.fontFamily}, sans-serif;
    --brand-font-family-text: ${(props) => props.theme.fontFamily}, sans-serif;
    --brand-font-family: ${(props) => props.theme.fontFamily}, sans-serif;
    --brand-font-default-color: rgba(0, 0, 0, 1);

    --brand-font-weight-light: 300;
    --brand-font-weight-400: 400;
    --brand-font-weight-normal: 400;
    --brand-font-weight-medium: 500;
    --brand-font-weight-semiBold: 600;
    --brand-font-weight-700: 700;
    --brand-font-weight-bold: 700;
    --brand-font-weight-heavy: 800;
    --brand-font-weight-black: 900;
    --brand-primary-text-color: #000000;
    --brand-secondary-text-color: #222222;
    --brand-light-secondary-text-color: #666666;
    --brand-neutral-text-color:#C4C4C4;
    --brand-primary-color:  rgba(66, 182, 130, 1);
    --brand-primary-disabled-color: #d3d3d3;
    --brand-primary-border-color: rgba(0, 0, 0, 0.1);
    --brand-primary-border-color: #ffe9d6;
    --brand-primary-white-color: rgba(255, 255, 255, 1);
    --brand-primary-white-color-1: rgba(243, 245, 249, 1);
    --brand-primary-black-color: rgba(0, 0, 0, 1);
    --brand-primary-black-light-color-o5: rgba(0, 0, 0, 0.5);
    --brand-primary-black-light-color-o1: rgba(0, 0, 0, 0.1);
    --brand-primary-black-light-color-o2: rgba(0, 0, 0, 0.2);
    --brand-primary-black-light-color: rgba(0, 0, 0, 0.05);
    --brand-primary-black-light-color-2: rgba(0, 0, 0, 0.7);
    --brand-primary-teal-color: rgba(0, 175, 147, 1);
    --brand-primary-teal-light-color: rgba(0, 175, 147, 0.1);
    --brand-primary-teal-light-color-2: rgba(0, 175, 147, 0.2);
    --brand-primary-green-color: rgba(66, 182, 130, 1);
    --brand-primary-orange-color: rgba(255, 109, 56, 1);
    --brand-primary-blue-color: rgba(88, 142, 206, 1);
    --brand-primary-error-color: rgba(232, 102, 105, 1);
    --brand-light-error-color: #df555b; 
    --brand-primary-grey-color: rgba(108, 108, 108, 1);
    --brand-primary-light-grey-color: rgba(225, 225, 225, 1);
    --brand-primary-light-divider-color: rgba(227, 227, 227, 1);
    --brand-primary-silver-color: rgba(243, 243, 243, 1);
    --brand-primary-silver-light-color: rgba(243, 243, 243, 0.5);
    --brand-primary-shadow-light-color: rgba(155, 155, 155, 0.22);
    --brand-primary-dark-blue-color: rgba(74, 144, 218, 1);
    --brand-primary-subtext-color:rgba(148, 148, 148, 1);
    --brand-primary-subheading-color: rgba(135, 130, 130, 1);
    --brand-primary-very-light-grey-color: rgba(246, 246, 246, 1);
    --brand-primary-very-dark-blue-color: rgba(15, 30, 61, 0.7);
    --brand-primary-light-dark-yellow-color: rgba(255, 237, 108, 1);
    --brand-primary-very-dark-yellow-color: rgba(255, 236, 107, 1);
    --brand-primary-filler-color:rgba(5, 163, 87, 1);
    --brand-light-bg-color: #f5f5f5;

    --brand-primary-red-color: rgba(250, 104, 99, 1);
    --brand-primary-red-dark-color: rgba(184, 68, 64, 1);
    --brand-primary-red-bg1-color: rgba(255, 223, 214, 1);
    --brand-primary-red-bg2-color: rgba(255, 240, 239, 1);
    --brand-primary-red-bg3-color: rgba(249, 238, 245, 1);

    --brand-primary-orange-color: rgba(255, 109, 56, 1);
    --brand-primary-orange-dark-color: rgba(201, 87, 38, 1);
    --brand-primary-orange-bg1-color: rgba(255, 233, 214, 1);
    --brand-primary-orange-bg2-color: rgba(255, 245, 239, 1);
    --brand-primary-orange-bg3-color: rgba(248, 232, 232, 1);

    --brand-primary-yellow-color: rgba(255, 200, 32, 1);
    --brand-primary-yellow-dark-color: rgba(204, 135, 0, 1);
    --brand-primary-yellow-bg1-color: rgba(255, 235, 173, 1);
    --brand-primary-yellow-bg2-color: rgba(254, 251, 237, 1);
    --brand-primary-yellow-bg3-color: rgba(253, 239, 225, 1);

    --brand-primary-green-color: rgba(66, 182, 130, 1);
    --brand-primary-green-dark-color: rgba(39, 119, 83, 1);
    --brand-primary-green-bg1-color: rgba(210, 232, 219, 1);
    --brand-primary-green-bg2-color: rgba(236, 248, 242, 1);
    --brand-primary-green-bg3-color: rgba(233, 244, 226, 1);

    --brand-primary-blue-color: rgba(88, 142, 206, 1);
    --brand-primary-blue-dark-color: rgba(46, 89, 141, 1);
    --brand-primary-blue-bg1-color: rgba(215, 229, 245, 1);
    --brand-primary-blue-bg2-color: rgba(238, 244, 250, 1);
    --brand-primary-blue-bg3-color: rgba(229, 241, 246, 1);
    --brand-primary-grey-very-light-color: rgba(228, 228, 228, 1);


    --brand-fluid-layout-width: 1440px;
    --brand-contained-layout-width: 1120px;

    --brand-primary-select-blue-shadow: rgba(225, 239, 248, 0.5);
    --brand-lighter-blue-color: rgba(81, 148, 209, 0.37);
    --brand-consult-primary-button-color: #22548A;
    --brand-product-deatils-color:rgba(33, 37, 41, 1);
    --brand-upsell-top-container-color: rgba(210, 232, 219, 1);
    --brand-cross-upsell-container-color: rgba(243, 243, 243, 1);
    --brand-navbar-boxshadow-color: 0px 5px 30px rgba(0, 0, 0, 0.1);

    --category-primary-color-value: 66, 182, 130;
    --category-primary-color: rgba(66, 182, 130, 1);
    --category-primary-dark-color: rgba(39, 119, 83, 1);
    --category-light-color: rgba(210, 232, 219, 1);
    --category-primary-bg1-color: rgba(210, 232, 219, 1);
    --category-primary-bg2-color: rgba(236, 248, 242, 1);
    --category-primary-bg3-color: rgba(233, 244, 226, 1);

    --category-profile-primary-color-value: rgba(66, 182, 130);
    --category-profile-primary-color: rgba(66, 182, 130, 1);
    --category-profile-primary-dark-color: rgba(39, 119, 83, 1);
    --category-profile-light-color: rgba(210, 232, 219, 1);
    --category-profile-primary-bg1-color: rgba(210, 232, 219, 1);
    --category-profile-primary-bg2-color: rgba(236, 248, 242, 1);
    --category-profile-primary-bg3-color:  #EDF3F8;
    
    --checkout-modal-desc-bg-color: rgba(37, 64, 120, 0.05);
    --checkout-modal-desc-text-color: rgba(37, 64, 120, 1);
    --checkout-modal-title-text-color: rgba(215, 108, 108, 1);
    --checkout-modal-positive-btn-color: rgba(215, 108, 108, 1);
    --checkout-modal-negative-btn-color: rgba(128, 168, 87, 1);
    --variation-outer-border-color: rgba(83, 124, 186, 1);

    --brand-tabs-carousel-left-icon-position: -53px;

    //brand font family
    --dls-brand-font-family:${(props) => props.theme.fontFamily}, sans-serif;

    //brand font lineHeight
    --dls-line-height: ${(props) => props.theme.lineHeight};

    //brand primary colors
    --dls-brand-primary-color-500: ${(props) => props.theme.color.PRIMARY[500]};
    --dls-brand-primary-color-400: ${(props) => props.theme.color.PRIMARY[400]};
    --dls-brand-primary-color-300: ${(props) => props.theme.color.PRIMARY[300]};
    --dls-brand-primary-color-200: ${(props) => props.theme.color.PRIMARY[200]};
    --dls-brand-primary-color-100: ${(props) => props.theme.color.PRIMARY[100]};

    //brand secondary colors
    --dls-brand-secondary-color-500: ${(props) =>
      props.theme.color.SECONDARY[500]};
    --dls-brand-secondary-color-400: ${(props) =>
      props.theme.color.SECONDARY[400]};
    --dls-brand-secondary-color-300: ${(props) =>
      props.theme.color.SECONDARY[300]};
    --dls-brand-secondary-color-200: ${(props) =>
      props.theme.color.SECONDARY[200]};
    --dls-brand-secondary-color-100: ${(props) =>
      props.theme.color.SECONDARY[100]};

    //success colors
    --dls-success-color-500:${(props) => props.theme.color.SUCCESS[500]};
    --dls-success-color-400:${(props) => props.theme.color.SUCCESS[400]};
    --dls-success-color-300:${(props) => props.theme.color.SUCCESS[300]};
    --dls-success-color-200:${(props) => props.theme.color.SUCCESS[200]};
    --dls-success-color-100:${(props) => props.theme.color.SUCCESS[100]};

    //warning colors
    --dls-warning-color-500:${(props) => props.theme.color.WARNING[500]};
    --dls-warning-color-400:${(props) => props.theme.color.WARNING[400]};
    --dls-warning-color-300:${(props) => props.theme.color.WARNING[300]};
    --dls-warning-color-200:${(props) => props.theme.color.WARNING[200]};
    --dls-warning-color-100:${(props) => props.theme.color.WARNING[100]};

    //error colors
    --dls-error-color-500:${(props) => props.theme.color.ERROR[500]};
    --dls-error-color-400:${(props) => props.theme.color.ERROR[400]};
    --dls-error-color-300:${(props) => props.theme.color.ERROR[300]};
    --dls-error-color-200:${(props) => props.theme.color.ERROR[200]};
    --dls-error-color-100:${(props) => props.theme.color.ERROR[100]};

    //pills colors
    --dls-background-selected-color:${(props) =>
      props.theme.color.PILLS.BACKGROUND_SELECTED};
    --dls-background-unselected-color:${(props) =>
      props.theme.color.PILLS.BACKGROUND_UNSELECTED};
    --dls-background-disabled-color:${(props) =>
      props.theme.color.PILLS.BACKGROUND_DISABLED};
    --dls-text-icon-selected:${(props) =>
      props.theme.color.PILLS.TEXT_ICON_SELECTED};
    --dls-text-icon-unselected:${(props) =>
      props.theme.color.PILLS.TEXT_ICON_UNSELECTED};
    --dls-text-icon-disabled:${(props) =>
      props.theme.color.PILLS.TEXT_ICON_DISABLED};

    //background colors
    --dls-backgound-color-500:${(props) => props.theme.color.BACKGROUND[500]};
    --dls-backgound-color-400:${(props) => props.theme.color.BACKGROUND[400]};
    --dls-backgound-color-300:${(props) => props.theme.color.BACKGROUND[300]};
    --dls-backgound-color-200:${(props) => props.theme.color.BACKGROUND[200]};
    --dls-backgound-color-100:${(props) => props.theme.color.BACKGROUND[100]};

    //text colors
    --dls-brand-text-colors:${(props) => props.theme.color.TEXT.BRAND_TEXT};
    --dls-primary-text-colors:${(props) => props.theme.color.TEXT.PRIMARY_TEXT};
    --dls-secondary-text-colors:${(props) =>
      props.theme.color.TEXT.SECONDARY_TEXT};
    --dls-disabled-text-colors:${(props) =>
      props.theme.color.TEXT.DISABLED_TEXT};

    //neutral colors
    --dls-black-color:${(props) => props.theme.color.NEUTRALS.BLACK};
    --dls-white-color:${(props) => props.theme.color.NEUTRALS.WHITE};
    --dls-text-field-stroke-color:${(props) =>
      props.theme.color.NEUTRALS.TEXT_FIELD_STROKE};

    //dividers colors
    --dls-divider-dark-color:${(props) => props.theme.color.DIVIDERS.DARK};
    --dls-divider-light-color:${(props) => props.theme.color.DIVIDERS.LIGHT};
    
    //rating stars colors
    --dls-rating-stars-dark-color:${(props) =>
      props.theme.color.RATING_STAR.DARK};
    --dls-rating-stars-light-color:${(props) =>
      props.theme.color.RATING_STAR.LIGHT};

    //tag colors
    --dls-highlighted-tag-background-color:${(props) =>
      props.theme.color.TAG.HIGHLIGHTED_TAG_BACKGROUND};
    --dls-highlighted-tag-text-color:${(props) =>
      props.theme.color.TAG.HIGHLIGHTED_TAG_TEXT};
    --dls-discount-tag-background-color:${(props) =>
      props.theme.color.TAG.DISCOUNT_TAG_BACKGROUND};
    --dls-discount-tag-text-color:${(props) =>
      props.theme.color.TAG.DEFAULT_TAG_TEXT};
    --dls-default-tag-background-color:${(props) =>
      props.theme.color.TAG.DEFAULT_TAG_BACKGROUND};
    --dls-default-tag-text-color:${(props) =>
      props.theme.color.TAG.DEFAULT_TAG_TEXT};

    //backup category colors
    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.PRIMARY[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.PRIMARY[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.PRIMARY[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.PRIMARY[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.PRIMARY[100]};

    //sizes
    --dls-size-0 : ${(props) => props.theme.sizes[0]};
    --dls-size-1 : ${(props) => props.theme.sizes[0.25]};
    --dls-size-2 : ${(props) => props.theme.sizes[0.5]};
    --dls-size-4 : ${(props) => props.theme.sizes[0.75]};
    --dls-size-8 : ${(props) => props.theme.sizes[1]};
    --dls-size-16 : ${(props) => props.theme.sizes[2]};
    --dls-size-24 : ${(props) => props.theme.sizes[3]};
    --dls-size-32 : ${(props) => props.theme.sizes[4]};
    --dls-size-40 : ${(props) => props.theme.sizes[5]};
    --dls-size-48 : ${(props) => props.theme.sizes[6]};
    --dls-size-56 : ${(props) => props.theme.sizes[7]};
    --dls-size-64 : ${(props) => props.theme.sizes[8]};
    --dls-size-72 : ${(props) => props.theme.sizes[9]};
    --dls-size-80 : ${(props) => props.theme.sizes[10]};
    --dls-size-88 : ${(props) => props.theme.sizes[11]};
    --dls-size-96 : ${(props) => props.theme.sizes[12]};
    --dls-size-104 : ${(props) => props.theme.sizes[13]};
    --dls-size-112 : ${(props) => props.theme.sizes[14]};
    --dls-size-120 : ${(props) => props.theme.sizes[15]};
    --dls-size-128 : ${(props) => props.theme.sizes[16]};
    --dls-size-136 : ${(props) => props.theme.sizes[17]};
    --dls-size-144 : ${(props) => props.theme.sizes[18]};
    --dls-size-152 : ${(props) => props.theme.sizes[19]};
    --dls-size-160 : ${(props) => props.theme.sizes[20]};
    --dls-size-168 : ${(props) => props.theme.sizes[21]};
    --dls-size-176 : ${(props) => props.theme.sizes[22]};
    --dls-size-184 : ${(props) => props.theme.sizes[23]};
    --dls-size-192 : ${(props) => props.theme.sizes[24]};
    --dls-size-200 : ${(props) => props.theme.sizes[25]};
    --dls-size-208 : ${(props) => props.theme.sizes[26]};
    --dls-size-216 : ${(props) => props.theme.sizes[27]};
    --dls-size-224 : ${(props) => props.theme.sizes[28]};
    --dls-size-232 : ${(props) => props.theme.sizes[29]};
    --dls-size-240 : ${(props) => props.theme.sizes[30]};
    --dls-size-248 : ${(props) => props.theme.sizes[31]};
    --dls-size-256 : ${(props) => props.theme.sizes[32]};
    --dls-size-264 : ${(props) => props.theme.sizes[33]};
    --dls-size-272 : ${(props) => props.theme.sizes[34]};
    --dls-size-280 : ${(props) => props.theme.sizes[35]};
    --dls-size-288 : ${(props) => props.theme.sizes[36]};
    --dls-size-296 : ${(props) => props.theme.sizes[37]};
    --dls-size-304 : ${(props) => props.theme.sizes[38]};
    --dls-size-312 : ${(props) => props.theme.sizes[39]};
    --dls-size-320 : ${(props) => props.theme.sizes[40]};
    --dls-size-328 : ${(props) => props.theme.sizes[41]};
    --dls-size-336 : ${(props) => props.theme.sizes[42]};
    --dls-size-344 : ${(props) => props.theme.sizes[43]};
    --dls-size-352 : ${(props) => props.theme.sizes[44]};
    --dls-size-360 : ${(props) => props.theme.sizes[45]};

    }

    [data-category ='reset']{
   --category-primary-color-value: 66, 182, 130;
    --category-primary-color: rgba(66, 182, 130, 1);
    --category-primary-dark-color: rgba(39, 119, 83, 1);
    --category-light-color: rgba(210, 232, 219, 1);
    --category-primary-bg1-color: rgba(210, 232, 219, 1);
    --category-primary-bg2-color: rgba(236, 248, 242, 1);
    --category-primary-bg3-color: rgba(233, 244, 226, 1);


      --dls-category-primary-color-500: ${(props) =>
        props.theme.color.PRIMARY[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.PRIMARY[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.PRIMARY[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.PRIMARY[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.PRIMARY[100]};
    }

    [data-category='eye'] {
    --category-primary-color: rgba(255, 109, 56, 1);
    --category-primary-dark-color: rgba(201, 87, 38, 1);
    --category-primary-bg1-color: rgba(255, 233, 214, 1);
    --category-primary-bg2-color: rgba(255, 245, 239, 1);
    --category-primary-bg3-color: rgba(248, 232, 232, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.BATH_AND_BODY[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.BATH_AND_BODY[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.BATH_AND_BODY[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.BATH_AND_BODY[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.BATH_AND_BODY[100]};
    }

    [data-category='nutrition'] {
    --category-primary-color-value: 66, 182, 130;
    --category-primary-color: rgba(66, 182, 130, 1);
    --category-primary-dark-color: rgba(39, 119, 83, 1);
    --category-primary-bg1-color: rgba(210, 232, 219, 1);
    --category-primary-bg2-color: rgba(236, 248, 242, 1);
    --category-primary-bg3-color: rgba(233, 244, 226, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.NUTRITION[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.NUTRITION[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.NUTRITION[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.NUTRITION[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.NUTRITION[100]};
    }
   
    [data-category='personal-care']{
    --category-primary-color: rgba(89, 142, 206, 1);
    --category-primary-dark-color: rgba(46, 89, 141, 1);
    --category-primary-bg1-color: rgba(222, 230, 239, 1);
    --category-primary-bg2-color: rgba(238, 244, 250, 1);
    --category-primary-bg3-color: rgba(226, 240, 244, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.BATH_AND_BODY[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.BATH_AND_BODY[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.BATH_AND_BODY[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.BATH_AND_BODY[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.BATH_AND_BODY[100]};
    }
    
    [data-category='oral-care'] {
    --category-primary-color: rgba(89, 142, 206, 1);
    --category-primary-dark-color: rgba(46, 89, 141, 1);
    --category-primary-bg1-color: rgba(222, 230, 239, 1);
    --category-primary-bg2-color: rgba(238, 244, 250, 1);
    --category-primary-bg3-color: rgba(226, 240, 244, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.ORAL_CARE[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.ORAL_CARE[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.ORAL_CARE[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.ORAL_CARE[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.ORAL_CARE[100]};
    }
    [data-category='Oral'] {
    --category-primary-color: rgba(89, 142, 206, 1);
    --category-primary-dark-color: rgba(46, 89, 141, 1);
    --category-primary-bg1-color: rgba(222, 230, 239, 1);
    --category-primary-bg2-color: rgba(238, 244, 250, 1);
    --category-primary-bg3-color: rgba(226, 240, 244, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.ORAL_CARE[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.ORAL_CARE[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.ORAL_CARE[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.ORAL_CARE[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.ORAL_CARE[100]};
    }
    [data-category='peppa'] {
    --category-primary-color: rgba(255, 148, 174, 1);
    --category-primary-dark-color: rgba(220, 70, 156, 1);
    --category-primary-bg1-color: rgba(255, 227, 243, 1);
    --category-primary-bg2-color: rgba(255, 244, 250, 1);
    --category-primary-bg3-color: rgba(255, 237, 240, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.PEEPA_PIG[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.PEEPA_PIG[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.PEEPA_PIG[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.PEEPA_PIG[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.PEEPA_PIG[100]};
    }

    [data-category='hygiene'] {
    --category-primary-color: rgba(83, 124, 186, 1);
    --category-primary-dark-color: rgba(137, 176, 234, 1);
    --category-primary-bg1-color: rgba(215, 229, 245, 1);
    --category-primary-bg2-color: rgba(238, 244, 250, 1);
    --category-primary-bg3-color: rgba(229, 241, 246, 1);

    --dls-category-primary-color-500: ${(props) =>
      props.theme.color.HYGIENE[500]};
    --dls-category-primary-color-400: ${(props) =>
      props.theme.color.HYGIENE[400]};
    --dls-category-primary-color-300: ${(props) =>
      props.theme.color.HYGIENE[300]};
    --dls-category-primary-color-200: ${(props) =>
      props.theme.color.HYGIENE[200]};
    --dls-category-primary-color-100: ${(props) =>
      props.theme.color.HYGIENE[100]};
    }

    [data-category='new'] {
    --category-primary-color: rgba(250, 104, 99, 1);
    --category-primary-dark-color: rgba(184, 68, 64, 1);
    --category-primary-bg1-color: rgba(255, 223, 214, 1);
    --category-primary-bg2-color: rgba(255, 240, 239, 1);
    --category-primary-bg3-color: rgba(249, 238, 245, 1);
    }

    button {
    cursor: pointer;
    }
    button:focus, button:active, button:target, button:visited{
      outline: 0;
      box-shadow: 2px 8px 12px 12px rgba(0,0,0,0.1)!important;
    }

    .product-description-tab {
      padding-top: 20px;
    }

    .ignore-bottom-margin-rule.ignore-bottom-margin-rule.ignore-bottom-margin-rule {
      margin-bottom: 0;
      @media (min-width: 768px) {
        margin-bottom: 0;
        
      }
    }

    .rcl-title-md.rcl-title-md.rcl-title-md {
      text-align: center;
      font-family: var(--brand-font-family-title);
      font-style: normal;
      font-weight: var(--brand-font-weight-normal);
      font-size: 24px;
      line-height: 28px;

       @media (min-width: 768px) {
        font-size: 48px;
        line-height: 55px;
      }
    }

    .rcl-fbt.rcl-fbt.rcl-fbt.rcl-fbt {
      padding-left: 0;
    }
`;

export default StyledLJAE;

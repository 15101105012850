import { IconProps } from '../types';

interface ArrowProps extends IconProps {
  direction?: string;
}

function Arrow({
  width = 17,
  height = 16,
  stroke = '#42B682',
  direction = 'up',
}: ArrowProps) {
  const rotate = direction === 'up' ? '0' : '180';
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 17 16"
      fill="none"
      style={{ transform: `rotate(${rotate}deg)`, flexShrink: 0 }}
    >
      <path
        d="M8.5026 12.6663V3.33301M8.5026 3.33301L3.83594 7.99967M8.5026 3.33301L13.1693 7.99967"
        stroke={stroke}
        stroke-width="1.2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default Arrow;

import React from 'react';
import Loader from '../Loader';
import Typography from '../Typography';
import { TypographyVariants } from '../Typography/types';
import { StyledButton } from './styles';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant: 'PRIMARY' | 'SECONDARY' | 'TERTIARY';
  onClick: () => void;
  disabled?: boolean;
  size?: 'SMALL' | 'MEDIUM' | 'LARGE';
  customClassName?: string;
  label: string;
  fullWidth?: boolean;
  customLabelClassName?: string;
  loading?: boolean;
}

function Button(props: ButtonProps) {
  const {
    type = 'button',
    variant = 'PRIMARY',
    size = 'MEDIUM',
    disabled = false,
    label,
    fullWidth = false,
    onClick,
    customLabelClassName = '',
    customClassName = '',
    loading,
  } = props;

  const labelTypographyVariant = React.useMemo(() => {
    switch (size) {
      case 'SMALL':
      case 'MEDIUM':
        return TypographyVariants.CTA_LABEL_SMALL;
      case 'LARGE':
      default:
        return TypographyVariants.CTA_LABEL_BIG;
    }
  }, [size]);
  if (!label) {
    return null;
  }

  return (
    <StyledButton
      type={type}
      data-variant={variant}
      data-size={size}
      data-disabled={disabled}
      disabled={disabled}
      data-isfullwidth={fullWidth}
      onClick={onClick}
      className={customClassName}
    >
      {loading ? (
        <Loader height={20} width={20} />
      ) : (
        <Typography
          variant={labelTypographyVariant}
          customClassName={`cta-label ${customLabelClassName}`}
        >
          {label}
        </Typography>
      )}
    </StyledButton>
  );
}

export default Button;

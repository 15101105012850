import styled from 'styled-components';

const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 12px;
  .tag {
    cursor: pointer;
    border: 0.8px solid var(--dls-brand-primary-color-200);
    padding: var(--dls-size-4) 6px var(--dls-size-4) var(--dls-size-8);
    border-radius: var(--dls-size-40);
    display: flex;
    align-items: center;
    gap: 4px;
  }
  .label {
    color: var(--dls-brand-primary-color-500);
  }
`;

export { StyledContainer };

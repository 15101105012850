import { RxDoctorSignatureWrapper } from './styles';
import { Image, Typography, TypographyVariants } from '../../atomic';
import { RxDoctorSignatureProps } from '@mosaic-wellness/fe-types';

const RxDoctorSignature = (props: RxDoctorSignatureProps) => {
  const { name = '', signatureUrl = '' } = props;

  return (
    <RxDoctorSignatureWrapper>
      <Image src={signatureUrl} customClassName="sign" />
      <Typography
        variant={TypographyVariants.TAG_REGULAR}
        customClassName="secondary-text"
      >
        {name}
      </Typography>
    </RxDoctorSignatureWrapper>
  );
};

export default RxDoctorSignature;

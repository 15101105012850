import { RxInfoListProps } from '@mosaic-wellness/fe-types';
import { Typography, TypographyVariants } from '../../atomic';
import { RxInfoListWrapper } from './styles';

const RxInfoList = (props: RxInfoListProps) => {
  const { infoItems = [] } = props;

  if (!infoItems.length) {
    return null;
  }

  return (
    <RxInfoListWrapper>
      {infoItems.map((item: string, index: number) => (
        <li key={'info-' + index}>
          <Typography variant={TypographyVariants.TAG_REGULAR}>
            <span dangerouslySetInnerHTML={{ __html: item }} />
          </Typography>
        </li>
      ))}
    </RxInfoListWrapper>
  );
};

export default RxInfoList;

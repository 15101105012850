import {MenuRowItemWrapper} from './styles'
import {
  AccordionRight,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '../../atomic'
import noop from 'lodash/noop'
import {MenuRowItemProps} from './types'

const MenuRowItem = (props: MenuRowItemProps) => {
  const {
    icon = '',
    title = '',
    subTitle = '',
    hideRightArrow = false,
    onClick = noop,
  } = props

  return (
    <MenuRowItemWrapper onClick={onClick} hideRightArrow={hideRightArrow}>
      <OptimizedImage
        customClassName="icon"
        source={icon}
        data-testid="action-card-icon"
      />
      <div className="details">
        <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
          {title}
        </Typography>
        <Typography variant={TypographyVariants.TAG_REGULAR}>
          {subTitle}
        </Typography>
      </div>
      {hideRightArrow ? null : <AccordionRight />}
    </MenuRowItemWrapper>
  )
}

export default MenuRowItem

type THeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

const HEADING_TAGS_MAPPING: Record<THeadingLevel, React.ElementType> = {
  1: 'h1',
  2: 'h2',
  3: 'h3',
  4: 'h4',
  5: 'h5',
  6: 'h6',
};

const IMAGE_WIDTH_MAPPING = {
  desktop: {
    FULL: 1080,
    HALF: 800,
    THIRD: 500,
    SMALL: 150,
    TINY: 50,
  },
  mobile: {
    FULL: 800,
    HALF: 500,
    THIRD: 300,
    SMALL: 150,
    TINY: 50,
  },
};

export { HEADING_TAGS_MAPPING, IMAGE_WIDTH_MAPPING };

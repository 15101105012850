import noop from 'lodash/noop'
import {
  Button,
  OptimizedImage,
  Typography,
  TypographyVariants,
} from '../../atomic'
import {StyledConsultPendingDuesOverlayWrapper} from './styles'
import {ConsultPendingDuesOverlayProps} from './types'

const ConsultPendingDuesOverlay = (props: ConsultPendingDuesOverlayProps) => {
  const {icon = '', title = '', subTitle = '', cta, onCtaClick = noop} = props

  return (
    <StyledConsultPendingDuesOverlayWrapper>
      <OptimizedImage source={icon} data-testid="consult-dues-icon" />
      <Typography variant={TypographyVariants.HEADING_SMALL_BOLD}>
        {title}
      </Typography>
      <Typography variant={TypographyVariants.BODY_BASE_REGULAR}>
        {subTitle}
      </Typography>
      <Button
        fullWidth
        customClassName="cta"
        variant="PRIMARY"
        label={cta?.label || ''}
        onClick={() => onCtaClick(cta)}
      />
    </StyledConsultPendingDuesOverlayWrapper>
  )
}

export default ConsultPendingDuesOverlay

import Typography from '../Typography';
import { TypographyVariants } from '../Typography/types';
import {
  StyledInputContainer,
  AssistiveText,
  StyledLabelContainer,
} from './styles';
import { SearchInputProps } from './types';

const SearchInput = (props: SearchInputProps) => {
  const {
    leftIcon,
    rightIcon,
    assistiveText,
    disabled,
    inputData,
    rightIconAction,
    leftIconAction,
    inputRef,
    label,
    prefixText,
    suffixText,
    suffixTextAction,
    isSuffixCta,
    isError,
  } = props;

  return (
    <>
      <StyledInputContainer
        disabled={disabled}
        leftChild={!!prefixText || !!leftIcon}
        isFilled={!!inputData.value}
        isSuffixCta={isSuffixCta}
        isError={isError}
      >
        {label && (
          <StyledLabelContainer disabled={disabled} isError={isError}>
            <Typography
              variant={TypographyVariants.X_SMALL}
              customClassName="label"
            >
              {label}
            </Typography>
          </StyledLabelContainer>
        )}
        {prefixText && (
          <div className="left-child">
            <Typography
              variant={TypographyVariants.BODY_BASE_REGULAR}
              customClassName="customText"
            >
              {prefixText}
            </Typography>
          </div>
        )}
        {leftIcon && (
          <div
            className="icon left-child"
            onMouseDown={(e) => e.preventDefault()}
            onClick={leftIconAction}
          >
            {leftIcon}
          </div>
        )}
        <input ref={inputRef} {...inputData} disabled={disabled} />
        {suffixText && (
          <div
            className="suffix-text right-child"
            onMouseDown={(e) => e.preventDefault()}
            onClick={suffixTextAction}
          >
            <Typography
              variant={
                isSuffixCta
                  ? TypographyVariants.BODY_BASE_BOLD
                  : TypographyVariants.BODY_BASE_REGULAR
              }
              customClassName="customText"
            >
              {suffixText}
            </Typography>
          </div>
        )}
        {rightIcon && (
          <div
            className="icon right-child"
            onMouseDown={(e) => e.preventDefault()}
            onClick={rightIconAction}
          >
            {rightIcon}
          </div>
        )}
      </StyledInputContainer>
      {assistiveText && (
        <AssistiveText disabled={disabled} isError={isError}>
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="label"
          >
            {assistiveText}
          </Typography>
        </AssistiveText>
      )}
    </>
  );
};

export default SearchInput;

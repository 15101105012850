import { StyledRxSectionHeaderWrapper } from './styles';
import { Typography, TypographyVariants } from '../../atomic';
import { RxSectionHeaderProps } from '@mosaic-wellness/fe-types';

const RxSectionHeader = (props: RxSectionHeaderProps) => {
  const { title = '' } = props;

  return (
    <StyledRxSectionHeaderWrapper>
      <Typography variant={TypographyVariants.X_SMALL} customClassName="title">
        {title}
      </Typography>
    </StyledRxSectionHeaderWrapper>
  );
};

export default RxSectionHeader;

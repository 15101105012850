import { TypographyVariants } from '../../atomic';

const getVariantAndStyles = (type: string): [TypographyVariants, string] => {
  switch (type) {
    case 'Black_Bold':
      return [TypographyVariants.CTA_LABEL_BIG, ''];
    case 'Red_Bold':
      return [TypographyVariants.CTA_LABEL_BIG, 'warning-text'];
    case 'Red_Regular':
      return [TypographyVariants.BODY_BASE_REGULAR, 'warning-text'];
    case 'Grey_Bold':
      return [TypographyVariants.CTA_LABEL_BIG, 'secondary-text'];
    case 'Grey_Regular':
      return [TypographyVariants.BODY_BASE_REGULAR, 'secondary-text'];
    default:
      return [TypographyVariants.BODY_BASE_REGULAR, ''];
  }
};

export { getVariantAndStyles };

import { StyledImageOrAnimationBannerWrapper } from './styles';
import { LottiePlayer, OptimizedImage } from '../../atomic';
import { ImageOrAnimationBannerProps } from '@mosaic-wellness/fe-types';

const ImageOrAnimationBanner = (props: ImageOrAnimationBannerProps) => {
  const {
    imageUrl = '',
    animationUrl = '',
    aspectRatio = 1,
    width = '100%',
    animationProps = {},
  } = props || {};
  if (animationUrl) {
    return (
      <LottiePlayer
        src={animationUrl}
        className="habit-banner"
        style={{ width, aspectRatio }}
        {...animationProps}
      />
    );
  }
  return (
    <StyledImageOrAnimationBannerWrapper aspectRatio={aspectRatio}>
      <OptimizedImage
        source={imageUrl}
        aspectWidth={width}
        customClassName="habit-banner"
      />
    </StyledImageOrAnimationBannerWrapper>
  );
};

export default ImageOrAnimationBanner;

import styled, { css } from 'styled-components';

const Row: any = styled.div`
  display: flex;
  flex-direction: row;
  ${({ customStyles }: any) =>
    customStyles &&
    css`
      ${customStyles}
    `}
`;

export default Row;

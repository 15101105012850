import { StyledRxDoctorDetailsWrapper } from './styles';
import { Image, Spacer, Typography, TypographyVariants } from '../../atomic';
import { RxDoctorDetailsProps } from '@mosaic-wellness/fe-types';

const RxDoctorDetails = (props: RxDoctorDetailsProps) => {
  const { imageUrl = '', title = '', subTitle = '' } = props;

  return (
    <StyledRxDoctorDetailsWrapper>
      <div className="details">
        {imageUrl && (
          <div className="avatar-container">
            <Image
              src={imageUrl}
              customClassName="avatar"
              data-testid="avatar"
            />
          </div>
        )}
        <div>
          <Typography variant={TypographyVariants.BODY_BASE_BOLD}>
            {title}
          </Typography>
          <Spacer variant="x-small" />
          <Typography
            variant={TypographyVariants.X_SMALL}
            customClassName="secondary-text"
          >
            {subTitle}
          </Typography>
        </div>
      </div>
    </StyledRxDoctorDetailsWrapper>
  );
};

export default RxDoctorDetails;

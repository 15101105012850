import styled, { css } from 'styled-components';

interface StyledInputContainerProps {
  disabled?: boolean;
  leftChild?: boolean;
  isFilled?: boolean;
  isSuffixCta?: boolean;
  isError?: boolean;
}

const StyledInputContainer = styled.div<StyledInputContainerProps>`
  position: relative;
  border: var(--dls-size-1) solid var(--brand-neutral-text-color);
  border-radius: var(--dls-size-8);
  display: flex;
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    css`
      border-color: var(--dls-divider-light-color);
      cursor: not-allowed;
    `};
  ${({ isError }) =>
    isError &&
    css`
      border-color: var(--dls-error-color-500);
    `};

  input {
    width: 100%;
    border: none;
    outline: none;
    font-size: var(--dls-size-16);
    color: var(--dls-primary-text-colors);
    padding: var(--dls-size-16) var(--dls-size-8);
    ${({ leftChild }) =>
      !leftChild &&
      css`
        padding-left: var(--dls-size-16);
      `};
    border-radius: var(--dls-size-8);
    &::placeholder {
      color: var(--brand-light-secondary-text-color);
      ${({ disabled }) =>
        disabled &&
        css`
          color: var(--dls-disabled-text-colors);
        `};
    }
    ${({ disabled }) =>
      disabled &&
      css`
        color: var(--dls-disabled-text-colors);
        background-color: var(--brand-primary-white-color);
        cursor: not-allowed;
      `};
    /* Hide number input controls in Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Hide number input controls in Firefox */
    &[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .icon {
    cursor: pointer;
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.5;
        pointer-events: none;
      `};
  }

  .left-child {
    padding-left: var(--dls-size-16);
  }

  .right-child {
    padding-right: var(--dls-size-8);
  }

  .customText {
    color: var(--brand-light-secondary-text-color);
    ${({ disabled }) =>
      disabled &&
      css`
        color: var(--dls-disabled-text-colors);
      `};
    ${({ isFilled }) =>
      isFilled &&
      css`
        color: var(--dls-primary-text-colors);
      `};
  }

  &:focus-within {
    border-color: var(--dls-primary-text-colors);
    .customText {
      color: var(--dls-primary-text-colors);
    }
  }
  .suffix-text {
    ${({ isSuffixCta }) =>
      isSuffixCta &&
      css`
        cursor: pointer;
        .customText {
          font-weight: 600;
          color: var(--dls-brand-primary-color-500);
        }
      `};
    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        pointer-events: none;
        .customText {
          color: var(--dls-disabled-text-colors);
        }
      `};
  }
`;

const AssistiveText = styled.div<StyledInputContainerProps>`
  padding-top: var(--dls-size-4);
  padding-left: var(--dls-size-16);
  .label {
    color: var(--brand-light-secondary-text-color);
    ${({ disabled }) =>
      disabled &&
      css`
        color: var(--dls-disabled-text-colors);
      `};
    ${({ isError }) =>
      isError &&
      css`
        color: var(--dls-error-color-500);
      `};
  }
`;

const StyledLabelContainer = styled.div<StyledInputContainerProps>`
  background-color: var(--brand-primary-white-color);
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  left: var(--dls-size-16);
  padding: 0 var(--dls-size-4);
  .label {
    color: var(--brand-light-secondary-text-color);
    ${({ disabled }) =>
      disabled &&
      css`
        color: var(--dls-disabled-text-colors);
      `};
    ${({ isError }) =>
      isError &&
      css`
        color: var(--dls-error-color-500);
      `};
  }
`;

export { StyledInputContainer, AssistiveText, StyledLabelContainer };

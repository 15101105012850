import styled from 'styled-components';

const StyledContainer = styled.div`
  width: 100%;
  .label {
    color: var(--brand-light-secondary-text-color);
  }
  .inputContainer {
    position: relative;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
  padding: var(--dls-size-8) 0;
  border-bottom: var(--dls-size-1) solid var(--dls-divider-light-color);
  
  input {
    width: 95%;
    border: none;
    font-style: italic;
    font-size: 18px;
    color: var(--brand-light-secondary-text-color);
    &::placeholder {
      color: var(--dls-disabled-text-colors);
    }
    &:focus {
      outline: none;
    }
  }

  .closeIcon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: ${(props: { showCloseIcon: boolean }) => (props.showCloseIcon ? 'block' : 'none')};
  }
`;

export { StyledContainer, InputContainer };

import styled, { css } from 'styled-components';

export const StyledExpandableSection = styled.div<{isCollapsible: boolean}>`
  padding: var(--dls-size-8) 10px;
  border-radius: var(--dls-size-4);
  border: var(--dls-size-1) solid var(--dls-divider-light-color);

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      color: var(--brand-light-secondary-text-color);
    }
    ${({isCollapsible}) => isCollapsible && css`
      cursor: pointer;
    `};
  }
`;

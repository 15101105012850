import styled from 'styled-components';
const CustomRadioButton = styled.div<{ selected: boolean, color: string }>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  border: var(--dls-size-1) solid ${props => (props.selected ? props.color : 'var(--brand-light-secondary-text-color)')};
  background-color: var(--brand-light-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    content: '';
    width: var(--dls-size-8);
    height: var(--dls-size-8);
    border-radius: 50%;
    background-color: ${props => (props.color)};
    display: ${props => (props.selected ? 'block' : 'none')};
  }
`;

export { CustomRadioButton };

import { useCallback } from 'react';
import { StyledConsultPaymentSummaryWrapper, StyledDivider } from './styles';
import TextItem from './TextItem';
import {
  ConsultPaymentSummaryListItem,
  ConsultPaymentSummaryProps,
} from '@mosaic-wellness/fe-types';

const ConsultPaymentSummary = (props: ConsultPaymentSummaryProps) => {
  const { listData = [] } = props;

  const renderList = useCallback(
    (item: ConsultPaymentSummaryListItem, index: number) => {
      const { type, data } = item || {};
      switch (type) {
        case 'text':
          return (
            <div className="body" key={`details-text-${index}`}>
              <TextItem {...data} />
            </div>
          );
        case 'divider':
          return (
            <div className="body" key={`details-divider-${index}`}>
              <StyledDivider
                role="separator"
              />
            </div>
          );
        case 'pendingDetails':
          return (
            <div className="body pending" key={`pending-details-text-${index}`}>
              <TextItem {...data} />
            </div>
          );
        default:
          return null;
      }
    },
    []
  );

  return (
    <StyledConsultPaymentSummaryWrapper>
      {listData.map(renderList)}
    </StyledConsultPaymentSummaryWrapper>
  );
};

export default ConsultPaymentSummary;

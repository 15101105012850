import Typography from '../Typography';
import { TypographyVariants } from '../Typography/types';
import { StyledInput, StyledInputMain } from './styles';
import { InputProps } from './types';

const Input = (props: InputProps) => {
  const { label } = props;

  return (
    <StyledInputMain>
      {props.label ? (
        <Typography
          variant={TypographyVariants.TAG_REGULAR}
          customClassName="secondary"
        >
          {label}
        </Typography>
      ) : null}
      <StyledInput {...props} />
    </StyledInputMain>
  );
};

export default Input;

import { RxPatientDetailsProps } from '@mosaic-wellness/fe-types';
import { Image, Spacer, Typography, TypographyVariants } from '../../atomic';
import { StyledRxPatientDetailsWrapper } from './styles';

const RxPatientDetails = (props: RxPatientDetailsProps) => {
  const {
    name = '',
    genderIcon = '',
    weight = '',
    height = '',
    age = '',
  } = props;

  return (
    <StyledRxPatientDetailsWrapper>
      <div className="flex-row">
        <Typography variant={TypographyVariants.TAG_BOLD}>{name}</Typography>
        <div className="gender-icon">
          <Image src={genderIcon} />
        </div>
      </div>
      <Spacer variant="x-small" />
      <div className="flex-row">
        {weight && (
          <>
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="secondary-text"
            >
              {weight}
            </Typography>
            <span className="divider" />
          </>
        )}
        {height && (
          <>
            <Typography
              variant={TypographyVariants.TAG_REGULAR}
              customClassName="secondary-text"
            >
              {height}
            </Typography>
            <span className="divider" />
          </>
        )}
        {age && (
          <Typography
            variant={TypographyVariants.TAG_REGULAR}
            customClassName="secondary-text"
          >
            {age}
          </Typography>
        )}
      </div>
    </StyledRxPatientDetailsWrapper>
  );
};

export default RxPatientDetails;

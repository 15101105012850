import styled from 'styled-components';

export const StyledRxDoctorDetailsWrapper = styled.div`
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  background: var(--brand-primary-white-color);
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  .details {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--dls-size-8);
  }
  .avatar-container {
    width: 36px;
  }
  .secondary-text {
    color: var(--brand-light-secondary-text-color);
  }
`;

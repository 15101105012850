import styled from 'styled-components';

const StyledGrid = styled.div`
  @media only screen and (max-width: 360px) {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 0;
    margin: 0px 16px;
    grid-gap: 8px;
  }
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    margin: 0px 32px;
  }
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    margin: 0px 32px;
  }
  @media only screen and (min-width: 1440px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    margin: 0px 152px;
  }
  @media only screen and (min-width: 3000px) {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 16px;
    margin: 956px 0px;
  }
`;

export { StyledGrid };

import styled from 'styled-components';

export const StyledRxConsultDetailsWrapper = styled.div`
  padding: 10px 0;
  border-radius: 12px;
  background: var(--brand-primary-white-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  .item {
    flex: 1;
  }
  .divider {
    width: var(--dls-size-2);
    height: var(--dls-size-16);
    background: var(--brand-primary-light-divider-color);
  }
  .price-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: var(--dls-size-4);
  }
  .info-icon {
    cursor: pointer;
  }
`;

import styled from 'styled-components';

const Loader = styled.div<{ height?: number; width?: number }>`
  border: 16px solid var(--dls-brand-primary-color-200);
  border-top: 16px solid var(--dls-brand-primary-color-500);
  border-radius: 50%;
  width: ${(props) => `${props.width}px` ?? '48px'};
  height: ${(props) => `${props.height}px` ?? '48px'};
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loader;

import { TextRow } from './styles';
import { Typography } from '../../../atomic';
import { getVariantAndStyles } from '../utils';
import { ConsultPaymentSummaryListItemData } from '@mosaic-wellness/fe-types';

const TextItem = (props: ConsultPaymentSummaryListItemData) => {
  const { label = '', value = '', labelType = '', valueType = '' } = props;

  const [labelVariant, labelStyle] = getVariantAndStyles(labelType);
  const [valueVariant, valueStyle] = getVariantAndStyles(valueType);

  return (
    <TextRow>
      <Typography variant={labelVariant} customClassName={labelStyle}>
        {label}
      </Typography>
      <Typography variant={valueVariant} customClassName={valueStyle}>
        {value}
      </Typography>
    </TextRow>
  );
};

export default TextItem;

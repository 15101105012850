import styled from 'styled-components';

const StyledConfirmationModalContainer = styled.div`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledConfirmationModal = styled.div`
  padding: var(--dls-size-16);
  width: var(--dls-size-360);
  background-color: white;
  border-radius: var(--dls-size-16);
  .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
  .closeIcon {
    background-color: white;
    cursor: pointer;
  }
  .secondary {
    color: var(--brand-light-secondary-text-color);
  }
`;

const StyledFooterCTAs = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--dls-size-8);
  .ctaLabel {
    font-size: 12px;
  }
`;

export {
  StyledConfirmationModalContainer,
  StyledConfirmationModal,
  StyledFooterCTAs,
};

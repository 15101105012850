import { DropdownArrow, DropdownList, SearchInput } from '../../atomic';
import { StyledSearchDropdownContainer } from './styles';
import { SearchDropdownProps } from './types';
import useSearchDropdown from './hook';

const SearchDropdown = (props: SearchDropdownProps) => {
  const { inputData, dropdownData } = props || {};

  const {
    isListOpen,
    doctorInputRef,
    handleCloseList,
    handleOpenList,
    handleListSelection,
  } = useSearchDropdown(props);

  return (
    <StyledSearchDropdownContainer>
      <SearchInput
        {...inputData}
        inputData={{
          ...inputData.inputData,
          onFocus: handleOpenList,
          onBlur: handleCloseList,
        }}
        inputRef={doctorInputRef}
        rightIcon={<DropdownArrow isClosed={!isListOpen} />}
        rightIconAction={isListOpen ? handleCloseList : handleOpenList}
      />
      {isListOpen && (
        <DropdownList {...dropdownData} handleSelection={handleListSelection} />
      )}
    </StyledSearchDropdownContainer>
  );
};

export default SearchDropdown;

import styled from 'styled-components';

export const StyledRxDiagnosisWrapper = styled.div`
  padding: 10px;
  background: var(--brand-light-bg-color);
  border: var(--dls-size-1) solid var(--dls-brand-primary-color-500);
  border-radius: var(--dls-size-4);
  .text {
    color: var(--brand-light-secondary-text-color);
  }
`;
